import React from 'react'
import Helmet from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import config from '../../data/SiteConfig'
import { formatDate } from '../utils/global'

import Layout from '../layout'
import PostPhotos from '../components/PostPhotos'
import PostTags from '../components/PostTags'
import SEO from '../components/SEO'

export default function PhotosetPostTemplate({ data }) {
  useStaticQuery(
    graphql`
      query PhotosetPostByID($slug: String) {
        contentfulPhotosets(slug: { eq: $slug }) {
          cover {
            localFile {
              childImageSharp {
                fixed(width: 400, quality: 80) {
                  src
                }
              }
            }
          }
          createdAt
          photos {
            localFile {
              childImageSharp {
                fluid(
                  maxHeight: 380
                  quality: 80
                  traceSVG: { background: "#edf8fa", color: "#c9f7ff" }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          slug
          tags
          title
        }
      }
    `
  )

  const post = data.contentfulPhotosets

  const date = formatDate(post.createdAt)
  const metaData = {
    title: post.title,
    description: post.description,
    image: post.cover.localFile.childImageSharp.fixed.src,
    slug: `/photosets/${post.slug}`,
  }
  return (
    <Layout>
      <Helmet>
        <title>{`${post.title} – ${config.siteTitle}`}</title>
      </Helmet>
      <SEO {...metaData} />
      <article className="container">
        <header className="single-header">
          <div className="flex">
            <h1>{post.title}</h1>
            <div className="post-meta">
              <time className="date">{date}</time>/
              <span className="photo-total">{`${post.photos.length ||
                '0'} 📷`}</span>
            </div>
            <PostTags tags={post.tags} />
          </div>
        </header>

        <PostPhotos photos={post.photos} />
      </article>
    </Layout>
  )
}
