import React from 'react'
import kebabcase from 'lodash.kebabcase'
import { Link } from 'gatsby'

export default function PostTags({ tags }) {
  return (
    <div className="tag-container">
      {tags &&
        tags.map(tag => (
          <Link
            key={tag}
            style={{ textDecoration: 'none' }}
            to={`/tags/${kebabcase(tag)}`}
          >
            <span>{tag}</span>
          </Link>
        ))}
    </div>
  )
}
