import React from 'react'
import Image from 'gatsby-image'

export default function PostPhotos({ photos }) {
  return (
    <div className="photo-container">
      {photos.map((photo, index) => {
        const { fluid } = photo.localFile.childImageSharp
        return (
          <div key={index} className="photo-item">
            <Image
              fluid={fluid}
              className="photo"
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
        )
      })}
    </div>
  )
}
